// components/BarcodeScanner.js
import React, { useState, useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';
import { Camera, X } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const BarcodeScanner = ({ companyId }) => {
    const [isScanning, setIsScanning] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [videoDevices, setVideoDevices] = useState([]);
    const [manualCode, setManualCode] = useState('');
    const [error, setError] = useState(null);
    const [hasCameraPermission, setHasCameraPermission] = useState(null);
    const videoRef = useRef(null);
    const codeReader = useRef(new BrowserMultiFormatReader());
    const navigate = useNavigate();
  
    // Check for camera permissions and devices
    useEffect(() => {
      const checkCameraPermissions = async () => {
        try {
          // First check if the browser supports getUserMedia
          if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setError('Your browser does not support camera access');
            return;
          }
  
          // Request camera permission
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          setHasCameraPermission(true);
          
          // Get list of video devices
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevs = devices.filter(device => device.kind === 'videoinput');
          setVideoDevices(videoDevs);
          
          if (videoDevs.length > 0) {
            setSelectedDevice(videoDevs[0].deviceId);
          }
  
          // Clean up the test stream
          stream.getTracks().forEach(track => track.stop());
        } catch (err) {
          console.error('Camera permission error:', err);
          if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            setError('Camera permission was denied. Please allow camera access to use the scanner.');
            setHasCameraPermission(false);
          } else {
            setError('Error accessing camera: ' + err.message);
            setHasCameraPermission(false);
          }
        }
      };
  
      checkCameraPermissions();
      return () => {
        stopScanning();
      };
    }, []);
  
    const startScanning = async () => {
      try {
        setIsScanning(true);
        setError(null);
  
        if (!hasCameraPermission) {
          setError('Camera permission is required to use the scanner');
          setIsScanning(false);
          return;
        }
        
        const constraints = {
          video: {
            deviceId: selectedDevice ? { exact: selectedDevice } : undefined,
            facingMode: "environment" // Prefer back camera on mobile devices
          }
        };
  
        try {
          // Test the camera access first
          const stream = await navigator.mediaDevices.getUserMedia(constraints);
          stream.getTracks().forEach(track => track.stop());
  
          const controls = await codeReader.current.decodeFromConstraints(
            constraints,
            videoRef.current,
            async (result, error) => {
              if (result) {
                await processBarcode(result.getText());
              }
              if (error && error?.name !== 'NotFoundException') {
                console.error('Scanning error:', error);
              }
            }
          );
  
          return () => {
            controls.stop();
          };
        } catch (err) {
          console.error('Error accessing camera:', err);
          setError('Failed to access camera. Please ensure camera permissions are granted.');
          setIsScanning(false);
        }
      } catch (err) {
        console.error('Error starting scanner:', err);
        setError('Failed to start scanner: ' + err.message);
        setIsScanning(false);
      }
    };

  const stopScanning = () => {
    codeReader.current.reset();
    setIsScanning(false);
  };

  const processBarcode = async (code) => {
    try {
      // First try to find by barcode
      let inventoryQuery = query(
        collection(db, 'inventory'),
        where('companyId', '==', companyId),
        where('barcode', '==', code)
      );
      
      let querySnapshot = await getDocs(inventoryQuery);
      
      // If no results, try to find by SKU
      if (querySnapshot.empty) {
        inventoryQuery = query(
          collection(db, 'inventory'),
          where('companyId', '==', companyId),
          where('sku', '==', code)
        );
        querySnapshot = await getDocs(inventoryQuery);
      }

      if (!querySnapshot.empty) {
        const item = querySnapshot.docs[0];
        navigate(`/inventory/${item.id}`);
      } else {
        setError(`No item found with code: ${code}`);
      }
    } catch (err) {
      console.error('Error processing barcode:', err);
      setError('Failed to process barcode');
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    if (manualCode) {
      await processBarcode(manualCode);
      setManualCode('');
    }
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4">Barcode Scanner</h2>
        
        {/* Manual Entry Form */}
        <form onSubmit={handleManualSubmit} className="mb-6">
          <div className="flex gap-2">
            <input
              type="text"
              value={manualCode}
              onChange={(e) => setManualCode(e.target.value)}
              placeholder="Enter barcode or SKU manually"
              className="flex-1 p-2 border rounded"
              autoComplete="off"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Search
            </button>
          </div>
        </form>

        {/* Camera Controls - Only show if browser supports camera */}
        {navigator.mediaDevices && navigator.mediaDevices.getUserMedia && (
          <div className="mb-4">
            {videoDevices.length > 0 && (
              <select
                value={selectedDevice}
                onChange={(e) => setSelectedDevice(e.target.value)}
                className="w-full p-2 border rounded mb-2"
              >
                {videoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId}`}
                  </option>
                ))}
              </select>
            )}

            <button
              onClick={isScanning ? stopScanning : startScanning}
              className={`w-full p-2 rounded flex items-center justify-center gap-2 ${
                isScanning 
                  ? 'bg-red-500 hover:bg-red-600' 
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white`}
            >
              {isScanning ? (
                <>
                  <X size={20} />
                  Stop Scanner
                </>
              ) : (
                <>
                  <Camera size={20} />
                  Start Scanner
                </>
              )}
            </button>
          </div>
        )}

        {/* Video Preview */}
        {isScanning && (
          <div className="relative aspect-video bg-gray-100 rounded overflow-hidden">
            <video
              ref={videoRef}
              className="absolute inset-0 w-full h-full object-cover"
              playsInline  // Important for iOS
            />
            {/* Scanning overlay */}
            <div className="absolute inset-0 border-2 border-blue-500 animate-pulse pointer-events-none" />
          </div>
        )}

        {/* Error Display */}
        {error && (
          <div className="mt-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
            {!hasCameraPermission && (
              <div className="mt-2 text-sm">
                To enable camera access:
                <ol className="list-decimal ml-4 mt-1">
                  <li>Click the camera icon in your browser's address bar</li>
                  <li>Select "Allow" for camera access</li>
                  <li>Reload the page</li>
                </ol>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarcodeScanner;