import { db } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, writeBatch, addDoc } from 'firebase/firestore';

// Utility function to get Shopify credentials
const getShopifyCredentials = async (companyId) => {
  const companyDoc = await getDoc(doc(db, 'companies', companyId));
  const shopifyData = companyDoc.data()?.shopifyIntegration;
  
  if (!shopifyData?.accessToken || !shopifyData?.shopUrl) {
    throw new Error('Shopify not connected');
  }
  
  return shopifyData;
};

// Push inventory to Shopify
export const pushInventoryToShopify = async (companyId, productId = null) => {
  try {
    const shopifyData = await getShopifyCredentials(companyId);
    const batch = writeBatch(db);
    
    // Query for products to sync
    const productsQuery = productId 
      ? query(collection(db, 'inventory'), where('id', '==', productId))
      : query(collection(db, 'inventory'), where('companyId', '==', companyId));
    
    const querySnapshot = await getDocs(productsQuery);
    const products = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    for (const product of products) {
      // Check if product exists in Shopify
      if (product.shopifyProductId) {
        // Update existing product
        await fetch(
          `https://${shopifyData.shopUrl}/admin/api/2024-01/products/${product.shopifyProductId}.json`,
          {
            method: 'PUT',
            headers: {
              'X-Shopify-Access-Token': shopifyData.accessToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product: {
                id: product.shopifyProductId,
                variants: [{
                  id: product.shopifyVariantId,
                  price: product.sell_price,
                  sku: product.sku,
                  inventory_quantity: product.quantity,
                  inventory_management: 'shopify'
                }]
              }
            })
          }
        );
      } else {
        // Create new product
        const response = await fetch(
          `https://${shopifyData.shopUrl}/admin/api/2024-01/products.json`,
          {
            method: 'POST',
            headers: {
              'X-Shopify-Access-Token': shopifyData.accessToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product: {
                title: product.name,
                body_html: product.description || '',
                vendor: 'Oneventory',
                variants: [{
                  price: product.sell_price,
                  sku: product.sku,
                  inventory_quantity: product.quantity,
                  inventory_management: 'shopify'
                }]
              }
            })
          }
        );
        
        const shopifyProduct = await response.json();
        
        // Save Shopify IDs back to our database
        batch.update(doc(db, 'inventory', product.id), {
          shopifyProductId: shopifyProduct.product.id,
          shopifyVariantId: shopifyProduct.product.variants[0].id,
          lastSyncedAt: new Date().toISOString()
        });
      }
    }
    
    await batch.commit();
    return { success: true, message: `Successfully synced ${products.length} products` };
  } catch (error) {
    console.error('Error pushing inventory to Shopify:', error);
    return { success: false, message: error.message };
  }
};

// Fetch orders from Shopify
export const fetchShopifyOrders = async (companyId, lastSyncedAt = null) => {
  try {
    const shopifyData = await getShopifyCredentials(companyId);
    
    // Construct URL with optional date filter
    let url = `https://${shopifyData.shopUrl}/admin/api/2024-01/orders.json?status=any`;
    if (lastSyncedAt) {
      url += `&created_at_min=${lastSyncedAt}`;
    }
    
    const response = await fetch(url, {
      headers: {
        'X-Shopify-Access-Token': shopifyData.accessToken
      }
    });
    
    if (!response.ok) {
      throw new Error('Failed to fetch Shopify orders');
    }
    
    const { orders } = await response.json();
    const batch = writeBatch(db);
    
    for (const order of orders) {
      // Check if order already exists
      const orderQuery = query(
        collection(db, 'salesOrders'),
        where('shopifyOrderId', '==', order.id.toString())
      );
      const existingOrders = await getDocs(orderQuery);
      
      if (existingOrders.empty) {
        // Create new sales order
        const orderData = {
          companyId,
          orderId: `SO-SHOPIFY-${order.order_number}`,
          customerSupplierName: `${order.customer.first_name} ${order.customer.last_name}`,
          orderDate: new Date(order.created_at).toISOString(),
          status: order.financial_status === 'paid' ? 'completed' : 'pending',
          total: parseFloat(order.total_price),
          shopifyOrderId: order.id.toString(),
          items: order.line_items.map(item => ({
            productId: item.product_id,
            variantId: item.variant_id,
            quantity: item.quantity,
            price: parseFloat(item.price)
          })),
          createdAt: new Date().toISOString(),
          source: 'shopify'
        };
        
        // Add the order
        const orderRef = await addDoc(collection(db, 'salesOrders'), orderData);
        
        // Update inventory quantities
        for (const item of order.line_items) {
          const inventoryQuery = query(
            collection(db, 'inventory'),
            where('shopifyProductId', '==', item.product_id.toString())
          );
          const inventoryDocs = await getDocs(inventoryQuery);
          
          if (!inventoryDocs.empty) {
            const inventoryDoc = inventoryDocs.docs[0];
            const currentQuantity = inventoryDoc.data().quantity;
            batch.update(doc(db, 'inventory', inventoryDoc.id), {
              quantity: currentQuantity - item.quantity,
              lastSyncedAt: new Date().toISOString()
            });
          }
        }
      }
    }
    
    await batch.commit();
    return { success: true, message: `Successfully synced ${orders.length} orders` };
  } catch (error) {
    console.error('Error fetching Shopify orders:', error);
    return { success: false, message: error.message };
  }
};

// Setup Shopify webhooks
export const setupShopifyWebhooks = async (companyId) => {
  try {
    const shopifyData = await getShopifyCredentials(companyId);
    
    // Webhook topics to create
    const webhooks = [
      {
        topic: 'inventory_levels/update',
        address: `${process.env.REACT_APP_API_URL}/webhooks/shopify/inventory`,
        format: 'json'
      },
      {
        topic: 'orders/create',
        address: `${process.env.REACT_APP_API_URL}/webhooks/shopify/orders`,
        format: 'json'
      }
    ];
    
    for (const webhook of webhooks) {
      await fetch(
        `https://${shopifyData.shopUrl}/admin/api/2024-01/webhooks.json`,
        {
          method: 'POST',
          headers: {
            'X-Shopify-Access-Token': shopifyData.accessToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ webhook })
        }
      );
    }
    
    return { success: true, message: 'Successfully set up webhooks' };
  } catch (error) {
    console.error('Error setting up Shopify webhooks:', error);
    return { success: false, message: error.message };
  }
}; 