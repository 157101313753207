import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { setupShopifyWebhooks } from '../services/shopifyService';

const ShopifyCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserAndHandle = async () => {
      try {
        if (!currentUser?.uid) {
          console.error('No user UID found');
          setError('Please log in to continue');
          setLoading(false);
          return;
        }

        console.log('Current user:', { 
          uid: currentUser.uid, 
          email: currentUser.email 
        });

        // Fetch user document to get company ID
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (!userDoc.exists()) {
          console.error('User document not found');
          setError('User profile not found');
          setLoading(false);
          return;
        }

        const userData = userDoc.data();
        console.log('User data:', { ...userData, companyId: userData.companyId || 'missing' });

        if (!userData.companyId) {
          console.error('No company ID in user data');
          setError('No company associated with this account');
          setLoading(false);
          return;
        }

        // Verify company exists
        const companyDoc = await getDoc(doc(db, 'companies', userData.companyId));
        if (!companyDoc.exists()) {
          console.error('Company document not found');
          setError('Company not found');
          setLoading(false);
          return;
        }

        // Now handle the callback
        await handleCallback(userData.companyId);
      } catch (err) {
        console.error('Error in auth check:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserAndHandle();
  }, [currentUser, location.search, navigate]);

  const handleCallback = async (companyId) => {
    try {
      // Get the authorization code from URL
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const shop = params.get('shop');
      const hmac = params.get('hmac');
      const timestamp = params.get('timestamp');

      if (!code || !shop) {
        throw new Error('Missing required parameters');
      }

      console.log('Received callback with:', { 
        shop, 
        code: '***',
        hmac: hmac ? '***' : 'missing',
        timestamp,
        companyId
      });

      // Construct the API URL without trailing slash
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '');
      if (!apiUrl) {
        throw new Error('API URL is not configured');
      }

      // Exchange the code for an access token
      const tokenResponse = await fetch(`${apiUrl}/shopify/callback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          code,
          shop,
          hmac,
          timestamp,
          companyId // Include company ID in the request
        })
      });

      console.log('Token response status:', tokenResponse.status);
      
      if (!tokenResponse.ok) {
        const errorText = await tokenResponse.text();
        console.error('Token response error:', errorText);
        throw new Error(`Failed to exchange authorization code: ${errorText}`);
      }

      let responseData;
      const responseText = await tokenResponse.text();
      console.log('Raw response:', responseText);
      
      try {
        responseData = JSON.parse(responseText);
      } catch (parseError) {
        console.error('Failed to parse response:', parseError);
        console.error('Response text:', responseText);
        throw new Error('Invalid response format from server');
      }

      if (!responseData.access_token) {
        console.error('Response data:', responseData);
        throw new Error('No access token received from server');
      }

      console.log('Successfully received access token');

      // Save the access token and shop URL
      await setDoc(doc(db, 'companies', companyId), {
        shopifyIntegration: {
          accessToken: responseData.access_token,
          shopUrl: shop,
          status: 'connected',
          connectedAt: new Date().toISOString(),
          scope: responseData.scope || ''
        }
      }, { merge: true });

      // Setup webhooks
      await setupShopifyWebhooks(companyId);

      // Redirect back to the integrations page
      navigate('/my-account');
    } catch (err) {
      console.error('Error in Shopify callback:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="p-8 max-w-2xl mx-auto">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Verifying your account...</p>
          <p className="text-sm text-gray-500 mt-2">This may take a few moments</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8 max-w-2xl mx-auto">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
          <h2 className="text-red-800 font-semibold mb-2">Connection Error</h2>
          <p className="text-red-600">{error}</p>
          <p className="text-sm text-gray-600 mt-2">
            Please ensure you are logged in and have proper access to a company account.
          </p>
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => navigate('/signin')}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Sign In
          </button>
          <button
            onClick={() => navigate('/my-account')}
            className="bg-gray-500 px-4 py-2 rounded hover:bg-gray-600"
          >
            Return to My Account
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 max-w-2xl mx-auto">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600">Connecting to Shopify...</p>
        <p className="text-sm text-gray-500 mt-2">This may take a few moments</p>
      </div>
    </div>
  );
};

export default ShopifyCallback; 