import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { pushInventoryToShopify, fetchShopifyOrders, setupShopifyWebhooks } from '../services/shopifyService';

const ShopifyConnect = () => {
  const { currentUser } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [shopifyStore, setShopifyStore] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [lastSync, setLastSync] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    const fetchCompanyId = async () => {
      try {
        if (!currentUser?.uid) {
          setError('No user found. Please log in.');
          setLoading(false);
          return;
        }

        console.log('Fetching company ID for user:', currentUser.uid);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        
        if (!userDoc.exists()) {
          setError('User document not found in database');
          setLoading(false);
          return;
        }

        const userData = userDoc.data();
        console.log('User data:', userData);

        if (!userData.companyId) {
          setError('No company associated with this user');
          setLoading(false);
          return;
        }

        setCompanyId(userData.companyId);
        checkConnection(userData.companyId);
      } catch (err) {
        console.error('Error fetching company ID:', err);
        setError('Failed to fetch company ID: ' + err.message);
        setLoading(false);
      }
    };

    fetchCompanyId();
  }, [currentUser]);

  const checkConnection = async (cId) => {
    try {
      console.log('Checking Shopify connection for company:', cId);
      const companyDoc = await getDoc(doc(db, 'companies', cId));
      
      if (!companyDoc.exists()) {
        console.error('Company document not found');
        setError('Company not found in database');
        setLoading(false);
        return;
      }

      const companyData = companyDoc.data();
      console.log('Company data:', companyData);
      
      const shopifyData = companyData.shopifyIntegration || {};
      console.log('Shopify integration data:', shopifyData);
      
      setIsConnected(!!shopifyData.accessToken);
      setShopifyStore(shopifyData.shopUrl || '');
      setLastSync(shopifyData.lastSyncAt);
      setError(null);
    } catch (err) {
      console.error('Error checking Shopify connection:', err);
      setError(`Failed to check Shopify connection: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const connectToShopify = async () => {
    try {
      if (!companyId) {
        setError('No company ID found. Please ensure you are part of a company.');
        return;
      }

      if (!shopifyStore) {
        setError('Please enter your Shopify store URL');
        return;
      }

      // Format store URL
      const formattedStore = shopifyStore.replace(/^https?:\/\//, '')
        .replace(/\/$/, '');

      if (!formattedStore.includes('myshopify.com')) {
        setError('Please enter a valid Shopify store URL (e.g., your-store.myshopify.com)');
        return;
      }

      console.log('Connecting to Shopify for company:', companyId);

      // Save initial connection state
      await setDoc(doc(db, 'companies', companyId), {
        shopifyIntegration: {
          shopUrl: formattedStore,
          status: 'connecting',
          createdAt: new Date().toISOString()
        }
      }, { merge: true });

      // Get Shopify app credentials
      const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_SHOPIFY_REDIRECT_URI || `${window.location.origin}/shopify/callback`;
      
      if (!clientId) {
        setError('Shopify app configuration is missing. Please contact support.');
        return;
      }

      console.log('Using redirect URI:', redirectUri);

      // Redirect to Shopify OAuth
      const shopifyConfig = {
        clientId,
        redirectUri,
        scopes: 'read_products,write_products,read_inventory,write_inventory,read_orders'
      };

      const authUrl = `https://${formattedStore}/admin/oauth/authorize?` +
        `client_id=${shopifyConfig.clientId}&` +
        `redirect_uri=${encodeURIComponent(shopifyConfig.redirectUri)}&` +
        `scope=${encodeURIComponent(shopifyConfig.scopes)}`;

      console.log('Redirecting to Shopify auth URL:', authUrl);
      window.location.href = authUrl;

    } catch (err) {
      console.error('Error connecting to Shopify:', err);
      setError(`Failed to connect to Shopify: ${err.message}`);
    }
  };

  const disconnectShopify = async () => {
    try {
      await setDoc(doc(db, 'companies', companyId), {
        shopifyIntegration: {
          shopUrl: null,
          accessToken: null,
          status: 'disconnected',
          disconnectedAt: new Date().toISOString()
        }
      }, { merge: true });
      
      setIsConnected(false);
      setShopifyStore('');
      setSyncStatus(null);
    } catch (err) {
      setError('Failed to disconnect from Shopify');
    }
  };

  const syncInventory = async () => {
    setSyncStatus('Syncing inventory...');
    setError(null);
    
    try {
      const result = await pushInventoryToShopify(companyId);
      if (result.success) {
        setSyncStatus('Inventory sync completed');
        await updateLastSyncTime();
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to sync inventory');
      setSyncStatus(null);
    }
  };

  const syncOrders = async () => {
    setSyncStatus('Fetching orders...');
    setError(null);
    
    try {
      const result = await fetchShopifyOrders(companyId, lastSync);
      if (result.success) {
        setSyncStatus('Orders sync completed');
        await updateLastSyncTime();
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to sync orders');
      setSyncStatus(null);
    }
  };

  const updateLastSyncTime = async () => {
    const now = new Date().toISOString();
    await setDoc(doc(db, 'companies', companyId), {
      shopifyIntegration: {
        lastSyncAt: now
      }
    }, { merge: true });
    setLastSync(now);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-4 border rounded-lg">
      <h3 className="text-lg font-semibold mb-4">Shopify Integration</h3>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {syncStatus && <div className="text-blue-500 mb-4">{syncStatus}</div>}
      
      {isConnected ? (
        <div>
          <div className="flex items-center mb-4">
            <span className="text-green-500 mr-2">✓</span>
            <span>Connected to {shopifyStore}</span>
          </div>
          
          <div className="flex flex-col gap-4">
            <button
              onClick={syncInventory}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Sync Inventory to Shopify
            </button>
            
            <button
              onClick={syncOrders}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Fetch Shopify Orders
            </button>
            
            {lastSync && (
              <p className="text-sm text-gray-600">
                Last synced: {new Date(lastSync).toLocaleString()}
              </p>
            )}
            
            <button
              onClick={disconnectShopify}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mt-4"
            >
              Disconnect from Shopify
            </button>
          </div>
        </div>
      ) : (
        <div>
          <input
            type="text"
            value={shopifyStore}
            onChange={(e) => setShopifyStore(e.target.value)}
            placeholder="your-store.myshopify.com"
            className="w-full p-2 border rounded mb-4"
          />
          <button
            onClick={connectToShopify}
            className="bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
          >
            Connect to Shopify
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopifyConnect;